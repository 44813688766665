import { get } from 'lodash'
import Container from '@theme/components/utils/Container'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  components: {
    Container,
    Wysiwyg: () => import('@theme/components/utils/Wysiwyg'),
  },
  data() {
    return {
      visible: false,
      icons: {
        danger: require('@icon/danger.svg'),
        info: require('@icon/info.svg'),
        success: require('@icon/success.svg'),
        warning: require('@icon/warning.svg'),
      },
    }
  },
  computed: {
    banner() {
      return this.$store.state.globals.topBanner
    },
    icon() {
      const iconName = get(this.banner, 'icon_text') || get(this.banner, 'icon[0]', false)
      return iconName ? get(this.icons, iconName) : false
    },
  },
  created() {
    if (this.banner && this.banner.enabled) {
      this.visible = true
    }
  },
  mounted() {
    this.checkClosed()
    setTimeout(() => {
      this.checkClosed()
    }, 1000)
  },
  methods: {
    checkClosed() {
      if (!this.isVisible()) {
        this.hide()
      }
    },
    isVisible() {
      return (
        this.banner &&
        this.banner.enabled &&
        this.$store.state.globals.topBannerLastModifiedClosed !== new Date(this.banner.lastModified).getTime()
      )
    },
    click() {
      this.$eventBus.$emit(eventDefinitions.PROMOTION.CLICK, {
        promotion: {
          name: 'TOP_BANNER',
        },
      })
    },
    close() {
      this.hide()
      this.$store.commit('globals/CLOSE_TOP_BANNER', new Date(this.banner.lastModified).getTime())
    },
    hide() {
      this.visible = false
      const root = document.documentElement
      root.style.setProperty('--top-banner-height', '0px')
    },
  },
}
